import React from "react"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import ResponsiveIFrame from "Components/ResponsiveIFrame"
import SEO from "Components/core/SEO"

const Tinkering: React.FC<GatsbyProps> = ({ location }) => {
  return (
    <StandardLayout location={location}>
      <SEO title="Yahtzee Redux" />
      <h2>Yahtzee Redux</h2>
      <a href="https://liammyles.github.io/yahtzee-redux/">
        View on Github Pages
      </a>{" "}
      or{" "}
      <a href="https://github.com/LiamMyles/yahtzee-redux">
        Checkout yahtzee-redux on Github repo
      </a>
      <ResponsiveIFrame src="https://liammyles.github.io/yahtzee-redux/" />
    </StandardLayout>
  )
}

export default Tinkering
